import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {
  Layout,
  Row,
  Col,
  Menu,
  Badge,
  Dropdown,
  Avatar,
  Popover,
  Input,
  AutoComplete,
  Typography,
} from "antd";
import {
  UserOutlined,
  SearchOutlined,
  DownOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import _ from "lodash";

/**
 * Actions
 */
import {changeSearchTerm, getUrlAvatar} from "auth/actions/userActions";
import {
  allFormsOfSavedObjects,
  savedDataInObj,
} from "auth/actions/objActions";

/**
 * Components
 */
import { CompanyLogo } from "components/NavBar/styles";
import { Logo } from "assets/icons";
import "reactjs-popup/dist/index.css";
import NotificationCenter from "../../NotificationCenter";
import SelectorUnit from "../SelectorUnit";

/**
 * Misc
 */
const { Header } = Layout;
const { Text } = Typography;

function TopBar({ user,avatar,getUrlAvatar,changeSearchTerm,search }) {
  const history = useHistory();
  const company = useSelector((state) => state.user.company);

  const renderTitle = (title) => <span>{title}</span>;
  const renderItem = (title, count) => ({
    key: Math.random(),
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <span>
          <FileTextOutlined /> {count}
        </span>
      </div>
    ),
  });

  const [forms, setForms] = useState([]);
  const [options, setOptions] = useState([]);
  const theme = {
    header: {
      fontFamily: "inherit",
      backgroundColor: "#FFFFFF",
      textColor: "#12B398",
      fontSize: "15px",
      borderRadius: "0px",
    },
    notification: {
      default: {
        fontSize: "14px",
        borderRadius: "0px",
        margin: "8px",
        backgroundColor: "#FFFFFF",
        textColor: "#3A424D",
        hover: { backgroundColor: "#ECECEC" },
        fontFamily: "inherit",
      },
      unread: {
        backgroundColor: "#F6F6F6",
        textColor: "#3A424D",
        hover: { backgroundColor: "#ECECEC" },
        state: { color: "#12B398" },
        borderRadius: "0px",
      },
      unseen: {
        backgroundColor: "#F8F5FF",
        textColor: "#3A424D",
        hover: { backgroundColor: "#ECECEC" },
        state: { color: "#12B398" },
      },
    },
    icon: { borderColor: "#12B398", width: "24px" },
    unseenBadge: { backgroundColor: "#12B398" },
    banner: {
      backgroundColor: "#F8F5FF",
      textColor: "#3A424D",
      fontSize: "14px",
    },
    dialog: {
      backgroundColor: "#F5F5F5",
      textColor: "#323232",
      accentColor: "#12B398",
    },
    footer: {
      fontFamily: "inherit",
      backgroundColor: "#FFFFFF",
      textColor: "#12B398",
      fontSize: "15px",
      borderRadius: "0px",
    },
  };

  useEffect(() => {
    getUrlAvatar(user.sub)
  }, [user]);


  useEffect(() => {
    allFormsOfSavedObjects(null,company.unitId).then((items) => {
      setForms(items.message);
    });
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={"#"} onClick={() => history.push("/perfil")}>Perfil</a>
      </Menu.Item>
      <Menu.Item>
        <a href={() => false} onClick={() => history.push("/logout")}>
          Sair
        </a>
      </Menu.Item>
    </Menu>
  );

  const showInfoLabel = (process.env.NODE_ENV !== 'production');
  return (
      <Fragment>
        {
            showInfoLabel && (<div
                style={{
                  textAlign: "center",
                  padding: "10px 0",
                  background: "#000f1e",
                }}
            >
              <Text
                  style={{
                    color: "#607d8b",
                  }}
              >
                <InfoCircleOutlined style={{marginRight: 5}}/> Você está utilizando
                uma versão de teste portanto algumas funcionalidades ainda estão em
                desenvolvimento.
              </Text>
            </div>)
        }
      <Header
        style={{
          textAlign: "center",
          padding: "0 20px",
        }}
      >
        <CompanyLogo>
          <a href="/dashboard/resumo" title={process.env.REACT_APP_NAME}>
            <Logo  style={{
              marginTop: -5,
              marginRight: 10,
              height:30
            }} />
          </a>
        </CompanyLogo>
        <Row type="flex" justify="end" align="middle">
          <Col span={24}>
            <div className="search-topbar">
              <AutoComplete
                value={search}
                style={{
                  display: "block",
                }}
                onChange={(value) => changeSearchTerm(value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if(e.target.value.length === 0) {
                      return;
                    }
                    document.location.replace(`/resultados/pesquisa/formularios/${e.target.value}`);
                  }
                }}
                options={options}
              >
                <Input
                  prefix={
                    <>
                      <SearchOutlined />
                      <span
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        Pesquisar
                      </span>
                    </>
                  }
                />
              </AutoComplete>
            </div>
          </Col>
          <Col
            style={{
              marginTop: -50,
              marginRight: 15,
            }}
            span={1.5}
          >
            <Badge>
              <a href={"#"}>Central de ajuda</a>
            </Badge>
          </Col>
          <Col
              style={{
                marginTop: -50,
                marginRight: 15,
              }}
              span={1.5}
          >
            <SelectorUnit/>
          </Col>
          <Col
            style={{
              marginTop: -35,
              marginLeft: 6,
            }}
            span={1}
          >
            {Object.keys(user).length > 0 ? <NotificationCenter/> : null}
          </Col>
          <Col
            style={{
              marginTop: -50,
              marginRight: 5,
            }}
            span={1}
          >
            <Dropdown overlay={menu}>
              <a href={() => false}>
                {
                  avatar === null ? <Avatar
                      style={{ verticalAlign: "middle" }}
                      icon={ <UserOutlined />}

                  /> : <Avatar
                      style={{ verticalAlign: "middle" }}
                      src={avatar}
                  />
                }
                {" "}
                <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>

      </Header>
    </Fragment>
  );
}

const mapStateToProps = ({ session,user }) => ({
  user: session.user,
  avatar: user.avatar,
  search: user.search,
});

const mapDispatchToProps = (dispatch) => ({
  getUrlAvatar: (userId) => dispatch(getUrlAvatar(userId)),
  changeSearchTerm: (term = "") => dispatch(changeSearchTerm(term)),
});

export default connect(mapStateToProps,mapDispatchToProps)(TopBar);
