import getApiUrl from "services/getApiUrl";

export const userApi = {
    updatePassword :(id, newPassword,newPasswordConfirm, unitId) => getApiUrl.post(`/auth/user/${id}/password`, {
        unitId: unitId,
        password: newPassword,
        passwordConfirm: newPasswordConfirm
    }),

    getUrlImageFromUser: async (id) => {
        try {
            // Faz a requisição da imagem (o response será um Blob)
            const response = await getApiUrl.get(`/auth/user/${id}/photo`, {
                params: {
                    random: Math.random(), // Evita cache
                },
                responseType: 'blob', // Importante para obter o Blob da imagem
            });

            if (response.status === 200) {
                const blob = response.data;

                // Converte o Blob em Base64
                return await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }); // Retorna a imagem em Base64
            } else {
                console.error('Erro ao buscar a imagem:', JSON.stringify(response));
                return null;
            }
        } catch (error) {
            console.error('Erro ao buscar a imagem:', JSON.stringify(error));
            return null;
        }
    },

    updateAvatarImageFromUser: async (id, image) => {
        const formData = new FormData();
        formData.append('file', image);
        return getApiUrl.post(`/auth/user/${id}/photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    getAll: async (params) => getApiUrl.get('/auth/user', {params}),

    getById: async (id,unitId) => getApiUrl.get(`/auth/user/${id}`,{
        params:{
            unitId
        }
    }),

    create: async (userData) => getApiUrl.post('/auth/user', userData),

    update: async (id, userData) => getApiUrl.patch(`/auth/user/${id}`, userData),

    delete: async (id,unitId) => getApiUrl.delete(`/auth/user/${id}`,{
        params:{
            unitId
        }
    }),

    getGroups: async (params) => getApiUrl.get(`/auth/group`, {params}),

    getGroupsByUnit: async (id) => getApiUrl.get(`/auth/unit/${id}/groups`),

    getGroupsByUser: async (id) => getApiUrl.get(`/auth/user/${id}/groups`),

    getRolesByUser: async (id) => getApiUrl.get(`/auth/user/${id}/roles`),

    getGroupDetails: async (id,unitId) => getApiUrl.get(`/auth/group/${id}/details`,{
        params:{
            unitId
        }
    }),

    getRoleDetails: async (id, unitId) => getApiUrl.get(`/auth/role/${id}/details`, {
        params:{
            unitId
        }
    }),

    removeUserFromGroup: async (id, groupId,userUuid = null,unitId) => getApiUrl.post(`/auth/group/remove-user`, {
        userId: id,
        groupId: groupId,
        userUuid:userUuid,
        unitId
    }),

    addUserToGroup: async (id, groupId,userUuid = null,unitId) => getApiUrl.post(`/auth/group/add-user`, {
        userId: id,
        groupId: groupId,
        userUuid:userUuid,
        unitId
    }),

    getCompaniesFromUser: async (id) => getApiUrl.get(`/auth/user/${id}/companies`),

    getRoles: async (params) => getApiUrl.get(`/auth/role`, {params}),

    createRole: async (params) => getApiUrl.post(`/auth/role`, params),

    createGroup: async (params) => getApiUrl.post(`/auth/group`, params),

    getUsersByUnit: async (id) => getApiUrl.get(`/auth/unit/${id}/users`),

    getRolesByUnit: async (id) => getApiUrl.get(`/auth/unit/${id}/roles`),

    addRoleToGroup: async (roleId, groupId,unitId) => getApiUrl.post(`/auth/group/add-role`, {
        roleId: roleId,
        groupId: groupId,
        unitId
    }),

    removeRoleFromGroup: async (roleId, groupId,unitId) => getApiUrl.post(`/auth/group/remove-role`, {
        roleId: roleId,
        groupId: groupId,
        unitId
    }),

    addUserToRole: async (userId, roleId,userUuid = null, unitId) => getApiUrl.post(`/auth/role/add-user`, {
        roleId: roleId,
        userId: userId,
        userUuid:userUuid,
        unitId,
    }),

    removeUserFromRole: async (userId, roleId,userUuid = null,unitId) => getApiUrl.post(`/auth/role/remove-user`, {
        roleId: roleId,
        userId: userId,
        userUuid:userUuid,
        unitId,
    }),

    getPermission: () => getApiUrl.get(`/auth/permission`),

    getPermissionsByUser: (id) => getApiUrl.get(`/auth/user/${id}/permissions`),

    getAllPermissionsByUser: (id) => getApiUrl.get(`/auth/user/${id}/permission`),

    getContexts: (unitId) => getApiUrl.get(`/auth/permission/contexts`, {
        params: {
            unitId
        }
    }),

    addPermissionToRole :(permissionSlug,roleId,unitId,contextId = null) => getApiUrl.post("/auth/role/add-permission",{
        roleId,
        permission:permissionSlug,
        contextId,
        unitId
    }),

    removePermissionFromRole :(permissionSlug,roleId,unitId,contextId = null) => getApiUrl.post("/auth/role/remove-permission",{
        roleId,
        permission:permissionSlug,
        contextId,
        unitId
    }),

    addPermissionToGroup :(permissionSlug,groupId,unitId,contextId = null) => getApiUrl.post("/auth/group/add-permission",{
        groupId,
        permission:permissionSlug,
        contextId,
        unitId
    }),

    removePermissionFromGroup :(permissionSlug,groupId,unitId,contextId = null) => getApiUrl.post("/auth/group/remove-permission",{
        groupId,
        permission:permissionSlug,
        contextId,
        unitId
    }),

    addPermissionToUser:(permissionSlug,userId = null, userUuid = null, unitId=null, contextId = null) => getApiUrl.post("/auth/user/add-permission",{
        userId,
        permission:permissionSlug,
        contextId,
        userUuid,
        unitId
    }),

    removePermissionFromUser:(permissionSlug,userId=null,userUuid = null,unitId=null,contextId = null) => getApiUrl.post("/auth/user/remove-permission",{
        userId,
        permission:permissionSlug,
        contextId,
        userUuid,
        unitId
    }),

    requestNewPassword: (id,unitId) =>  getApiUrl.post(`/auth/user/${id}/new-password`,{},{
        params:{
            unitId
        }
    }),

    removeGroup: (id,unitId) => getApiUrl.delete(`/auth/group/${id}`,{
        params:{
            unitId
        }
    }),

    removeRole: (id,unitId) => getApiUrl.delete(`/auth/role/${id}`,{
        params:{
            unitId
        }
    }),

    getCompanyDetails : (id) => getApiUrl.get(`/auth/company/${id}`),

    createUnit:(data) => getApiUrl.post(`/auth/unit`, data),

    deleteUnit:(id) => getApiUrl.delete(`/auth/unit/${id}`),

    restoreUnit:(id) => getApiUrl.patch(`/auth/unit/${id}/restore`),

    updateUnit: (id, values)  => getApiUrl.patch(`/auth/unit/${id}`, values),

    getUnitsAvailableByCompany: (id, withUnitDeleted = false)  => getApiUrl.get(`/auth/company/${id}/units`,{
        params:{
            withUnitDeleted: withUnitDeleted ? 1 : 0
        }
    }),

    getUnitsFromUser: (id)  => getApiUrl.get(`/auth/user/${id}/units`),

    addUserToUnit: (userUuid, unitId, unitIdOrigin) => getApiUrl.post(`/auth/unit/add-user`, {
        userUuid,
        unitId,
        unitIdOrigin
    }),

    removeUserFromUnit: (userUuid, unitId,unitIdOrigin) => getApiUrl.post(`/auth/unit/remove-user`, {
        userUuid, unitId,unitIdOrigin
    }),

    searchAll: (term,unitId, startDate = null, endDate = null, isFullSearch = false, page = 1, formName = null, orderByField = null, orderBySort=null) => {
        let data = {
            term,
            unitId,
            isFullSearch
        }
        if(startDate && endDate){
            data = {
                ...data,
                startDate,
                endDate
            }
        }

        if(formName){
            data = {
                ...data,
                formName,
                page,
                orderByField,
                orderBySort
            }
        }

        return getApiUrl.post("/objetos/formularios/pesquisa",data)
    },

    downloadSearchAll: (term,unitId, startDate = null, endDate = null, isFullSearch = false, page = 1, formName = null, orderByField = null, orderBySort=null) => {
        let data = {
            term,
            unitId,
            isFullSearch
        }
        if(startDate && endDate){
            data = {
                ...data,
                startDate,
                endDate
            }
        }

        if(formName){
            data = {
                ...data,
                formName,
                page,
                orderByField,
                orderBySort
            }
        }

        return getApiUrl.post("/objetos/formularios/pesquisa/download",data,{
            responseType: 'blob',
        })
    },
};
