import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Col, ConfigProvider, DatePicker, Layout, PageHeader, Row, Select, Spin, Switch,} from "antd";
import {ProTable} from "@ant-design/pro-components";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";
import datePickerLocale from "antd/lib/locale/pt_BR";
import ptBRIntl from "antd/lib/locale/pt_BR";
import { DownloadOutlined } from "@ant-design/icons";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import {LayoutTopSide} from "components/NavBar/styles";
import "reactjs-popup/dist/index.css";
import {useDispatch, useSelector} from "react-redux";
import {changeSearchTerm} from "../../../auth/actions/userActions";
import {userApi} from "../../../services/actions/users";
import buildTableData from "../../../components/Utils/formData";
import buildColumns from "../../../components/Utils/formColumns";
const {RangePicker} = DatePicker;

const FormSearchResults = () => {

    const dispatch = useDispatch();
    const {search = ""} = useParams();
    const [loading, setLoading] = useState(true)
    const [loadingDownload,setLoadingDownload] = useState(false);
    const [formsFilter, setFormsFilter] = useState([]);
    const [categoriesFilter, setCategoriesFilter] = useState([]);
    const [forms, setForms] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allData, setAllData] = useState([]);
    const [isFullSearch, setIsFullSearch] = useState(false);
    const [dates, setDates] = useState(null);
    const company = useSelector((state) => state.user.company);

    const [sorted,setSorted] = useState({});

    const fetchResultByForm = async (form = null,page = 1,sortFinal = null,isExport = false) => {

        try {
            if(sortFinal && form){
                setSorted({
                    ...sorted,
                    [form._id]:sortFinal
                })
            }

            const sort = form ? sortFinal ? sortFinal : sorted[form._id] : null;

            const {order = null, field = null} = sort || {};

            let startDate, endDate = null;
            if (dates?.length === 2) {
                startDate = moment(dates[0]).format("YYYY-MM-DD 00:00:00");
                endDate = moment(dates[1]).format("YYYY-MM-DD 00:00:00");
            }
            if(isExport){
                const {data, status, headers} = await userApi.downloadSearchAll(search, company.unitId, startDate, endDate, isFullSearch, page, form ? form.formName : null,order ? field : null,order);
                if (status === 200) {
                    const fileName = headers['x-filename'] || "pesquisa.xlsx";
                    await downloadFile(fileName,data)
                }
            }else{
                const {
                    data,
                    status
                } = await userApi.searchAll(search, company.unitId, startDate, endDate, isFullSearch, page, form.formName,order ? field : null,order);
                if (status === 200) {
                    if (Array.isArray(data) && data.length > 0) {
                        const first = data[0];
                        setAllData(prev => prev.map(f => f.form.schemaName === form.schemaName ? {
                            ...f,
                            items: first.items
                        } : f));
                    }
                }
            }


        } catch (ex) {
            console.log(ex)
        }
    }

    const downloadFile = async (fileName='pesquisa.xlsx', data) => {
        try {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(data);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Erro ao fazer o download:', error);
        }
    };

    const searchData = useCallback(async () => {
        try {
            setLoading(true);
            let startDate, endDate = null;
            if (dates?.length === 2) {
                startDate = moment(dates[0]).format("YYYY-MM-DD 00:00:00");
                endDate = moment(dates[1]).format("YYYY-MM-DD 00:00:00");
            }
            const {data, status} = await userApi.searchAll(search, company.unitId, startDate, endDate, isFullSearch);
            if (status === 200) {
                const allForms = data.map(item => _.omit(item.form, ['fields', 'fieldsLabel', 'dateField']));
                const allCategories = _.uniqBy(data.flatMap(item => item.form.categories), '_id');
                setForms(allForms);
                setCategories(allCategories);
                setAllData(data);
                setCategoriesFilter(allCategories.map(item => item._id));
                setFormsFilter(allForms.map(item => item.schemaName));
            }
        } catch (ex) {
            console.error("Erro ao buscar dados:", ex);
        } finally {
            setLoading(false);
        }
    }, [search, company.unitId, dates, isFullSearch]);

    const  handleDownloadFile = async (form = null) =>  {
        try {
            setLoadingDownload(true);
            await fetchResultByForm(form,1,null,true);
        }catch (e) {
            console.log(e);
        }finally {
            setLoadingDownload(false);
        }

    }

    const renderTable = useCallback(({form, items, pagination}) => {
        if (!formsFilter.includes(form.schemaName)) {
            return null;
        }
        const fields = isFullSearch ? form.fields : form.fieldsLabel;
        const columns = fields.map(item => item.field);
        const refFields = [
            ..._.flatMap(form.fields, "refFields"),
            ..._.flatMap(form.fields, "getValueOf")
        ].filter(Boolean);



        return (
            <Col span={24} key={form.schemaName}>
                <ConfigProvider locale={ptBRIntl}>
                    <ProTable
                        toolbar={{
                            actions: [
                                <Button  loading={loadingDownload} key="export" type={'primary'} icon={<DownloadOutlined />} onClick={() =>{
                                    handleDownloadFile(form)
                                }}>
                                    Exportar
                                </Button>,
                            ],
                        }}

                        headerTitle={` ${form.formName} - ${pagination.total} registros encontrados`}
                        columns={buildColumns(columns, form.fields, refFields, true)}
                        dataSource={buildTableData(items)}
                        rowKey="_id"
                        search={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: event => {
                                    window.open(`/formularios/registros/${decodeURIComponent(form.schemaName)}?id=${encodeURIComponent(record._id)}`, "_blank");
                                }
                            };
                        }}
                        onChange={ async (pagination, __, sorter) => {
                            await fetchResultByForm(form,pagination.current,sorter);
                        }}
                        pagination={_.omit(pagination, ['page'])}
                        scroll={{y: 300}}
                    />
                </ConfigProvider>
            </Col>
        );
    }, [isFullSearch, formsFilter,allData]);


    useEffect(() => {
        dispatch(changeSearchTerm(search));
        searchData();
    }, [dispatch, searchData]);

    const handleChangeCategoriesFilter = (selectedCategories) => {
        setCategoriesFilter(selectedCategories);

        // Obtém formulários que possuem pelo menos uma das categorias selecionadas
        const filteredForms = forms.filter(form =>
            form.categories.some(cat => selectedCategories.includes(cat._id))
        ).map(form => form.schemaName);

        // Preserva os formulários sem categoria que já estavam selecionados
        const preservedForms = formsFilter.filter(formName => {
            const form = forms.find(f => f.schemaName === formName);
            return form && form.categories.length === 0;
        });

        setFormsFilter([...new Set([...filteredForms, ...preservedForms])]);
    };

    const handleChangeFormsFilter = (selectedForms) => {
        setFormsFilter(selectedForms);

        // Adiciona automaticamente as categorias dos formulários selecionados
        const updatedCategories = _.uniqBy(
            forms
                .filter(form => selectedForms.includes(form.schemaName))
                .flatMap(form => form.categories),
            "_id"
        ).map(category => category._id);

        setCategoriesFilter(updatedCategories);
    };


    const resultData = (
        <>
            <Row
                style={{
                    padding: "24px 15px 24px 15px",
                    margin: 0,
                    background: "white",
                }}
            >
                <Col span={12} style={{padding: "5px"}}>
                    <span>Data </span>
                    <ConfigProvider locale={datePickerLocale}>
                        <RangePicker
                            value={dates}
                            onChange={(values) => setDates(values)}
                            ranges={{
                                Hoje: [moment(), moment()],
                                "Este mês": [
                                    moment().startOf("month"),
                                    moment().endOf("month"),
                                ],
                            }}
                            format="DD/MM/YYYY"
                            showTime
                        />
                    </ConfigProvider>
                </Col>

                <Col
                    span={6}
                    style={{
                        padding: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}
                >
                    <span style={{marginRight: "5px"}}>Pesquisar em todos os campos</span>
                    <Switch checked={isFullSearch} onChange={() => setIsFullSearch(!isFullSearch)}/>
                </Col>
                <Col
                    span={6}
                    style={{
                        padding: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}
                >
                    <Button  loading={loadingDownload} key="export" type={'dashed'} icon={<DownloadOutlined />} onClick={() =>{handleDownloadFile()}}>
                        Exportar tudo
                    </Button>,
                </Col>


                <Col span={12} style={{padding: "5px"}}>
                    <span>Formulários</span>
                    <Select
                        allowClear={true}
                        onChange={handleChangeFormsFilter}
                        mode="multiple"
                        style={{
                            width: "100%",
                        }}
                        options={forms?.map((form) => ({
                            value: form.schemaName,
                            label: form.formName,
                        }))}
                        value={formsFilter}
                    />
                </Col>
                <Col span={12} style={{padding: "5px"}}>
                    <span>Categorias</span>
                    <Select
                        allowClear={true}
                        mode={'multiple'}
                        style={{
                            width: "100%",
                        }}
                        options={categories?.map((category) => ({
                            value: category._id,
                            label: category.name,
                        }))}
                        onChange={handleChangeCategoriesFilter}
                        value={categoriesFilter}
                    />
                </Col>
            </Row>

            {loading ? (
                <Row
                    style={{
                        padding: "24px 15px 24px 15px",
                        margin: 0,
                        background: "white",
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            padding: "5px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Spin tip="Carregando pesquisa..." size={"large"}/>
                    </Col>
                </Row>
            ) : (
                <Row
                    style={{
                        padding: "24px 15px 24px 15px",
                        margin: 0,
                        background: "white",
                    }}
                >
                    {allData.map((item) => renderTable(item))}
                </Row>
            )}
        </>
    );


    return (
        <Layout>
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout style={{padding: "0 53px 53px", height: "100vh"}}>
                        <PageHeader
                            style={{margin: "16px 0px 5px", paddingLeft: 0}}
                            title={`Resultados da pesquisa${_.isEmpty(search) ? '' : `: ${search}`}`}
                        />
                        {resultData}
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

export default FormSearchResults;
