import { io } from 'socket.io-client';
import { getUser } from '../utils/user-manager';

const SOCKET_URL = window.location.origin;

let socket; // Armazena a instância única

const getSocketInstance = async () => {
    if (!socket) {
        try {
            const user = await getUser();
            if (user?.access_token) {
                socket = io(SOCKET_URL, {
                    transports: ["polling"],
                    extraHeaders: {
                        Authorization: `Bearer ${user.access_token}`,
                    },
                });
                socket.connect(); // Conecta imediatamente com o token
            } else {
                console.error('Token de acesso não encontrado');
            }
        } catch (error) {
            console.error('Erro ao obter token ou ao criar a instância do socket:', error);
        }
    }
    return socket;
};

export default getSocketInstance;
