const initialState = {
    ready: false,
    companies: [],
    company: null,
    error: null,
    avatar: null,
    permissions: [],
    search: ""
};

export const GET_URL_AVATAR = 'core/report/GET_URL_AVATAR';
export const UPDATE_AVATAR = 'core/report/UPDATE_AVATAR';
export const ERROR_ON_UPDATE_AVATAR = 'core/report/ERROR_ON_UPDATE_AVATAR';
export const GET_COMPANIES_DATA = 'core/report/GET_COMPANIES_DATA';
export const ERROR_TO_GET_COMPANIES_DATA = 'core/report/ERROR_TO_GET_COMPANIES_DATA';
export const CHANGE_COMPANY_SELECTION = 'core/report/CHANGE_UNIT_SELECTION';
export const GET_USER_PERMISSIONS = 'core/report/GET_USER_PERMISSIONS';
export const READY_APP = 'core/report/READY_APP';
export const CHANGE_SEARCH_TERM = 'core/report/CHANGE_SEARCH_TERM';

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case READY_APP:
            return  {
                ...state,
                ready: true,
            }
        case GET_USER_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            }
        case ERROR_ON_UPDATE_AVATAR:
            return {
                error: action.payload.error,
                avatar: null
            }
        case GET_URL_AVATAR:
            return {
                ...state,
                error: null,
                avatar: action.payload,
            };
        case UPDATE_AVATAR:
            return {
                ...state,
                error: null,
                avatar: action.payload,
            };
        case GET_COMPANIES_DATA:
            return {
                ...state,
                company: null,
                companies: action.payload,
            }
        case ERROR_TO_GET_COMPANIES_DATA:
            return {
                ...state,
                error: action.payload.error,
                company: null,
                companies: []
            }
        case CHANGE_COMPANY_SELECTION:
            return {
                ...state,
                company: action.payload,
            }
        case CHANGE_SEARCH_TERM:
            return {
                ...state,
                search: action.payload,
            }
        default:
            return state;
    }
}
