import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Avatar,
  Modal,
  Table,
  Empty,
  Space,
  Input,
  notification,
  Button,
} from "antd";
import {
  FileSyncOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Actions
 */
import {
  allCategoriesWithObj,
  addHitByObj,
  searchAllByObj,
} from "auth/actions/objActions";
import PermissionWrapperComponent from "../../../components/PermissionWrapperComponent";
import {checkPermissionsFromUser, getAllPermissionsByUnit, hasAnyPermission} from "../../../utils/permission";

/**
 * Misc
 */
const { Content } = Layout;
const { confirm } = Modal;

function AllForms({ user }) {
  const permissions = useSelector((state) => state.user.permissions);
  const company = useSelector((state) => state.user.company);
  const history = useHistory();
  const location = useLocation();
  const [forms, setForms] = useState([]);
  const [cleanForms, setCleanForms] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [permissionEntity, setPermissionEntity] = useState(null)

  const sortForms = (forms) => {
    let sortedForms = [...forms];
    sortedForms.sort((a, b) => {
        return b.hit - a.hit;
    });
    return sortedForms;
  };

  const newForm = () => {
    history.push({
      pathname: `/formularios/novo`,
    });
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
        allCategoriesWithObj(company.unitId)
          .then((items) => {
              const permissionEntityData = getAllPermissionsByUnit(permissions, company.unitId, [
                  "EDIT_FORM",
                  "DELETE_FORM",
                  "CREATE_FIELD_IN_FORM",
                  "EDIT_FIELD_IN_FORM",
                  "DELETE_FIELD_IN_FORM",
                  "ORDER_FIELDS_IN_FORM",
                  "INSERT_DATA_IN_FORM",
                  "EDIT_DATA_IN_FORM",
                  "DELETE_DATA_IN_FORM",
                  "VIEW_DATA_FROM_FORM",
                  "IMPORT_DATA_IN_FORM",
                  "VIEW_DATA_ARCHIVED_FROM_FORM"
              ]);

              setPermissionEntity(permissionEntityData);
              const permissionEntityById = permissionEntityData
                  .filter(item => item.context)
                  .map(item => item.context.entity_id)
              var results = items.message
                  .filter(item => permissionEntityById.includes(item._id))
                  .map((values) => ({
                      _id: values._id,
                      name: values.name,
                      shortName: values.name.replace("obj_", ""),
                      categories: values.categories.join(", "),
              }));
              let sortedForms = sortForms(results);
              setCleanForms(sortedForms)
              setForms(sortedForms);
          })
          .catch((err) => {
                  notification["error"]({
                      message: "Error",
                      description: err.message
                          ? err.message
                          : "Something went wrong, please try again!",
                  });
              }
          );
    }
  }, [user, location]);

  const routes = [
    {
      breadcrumbName: "formulários",
    },
    {
      breadcrumbName: "todos",
    },
  ];

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);

    if (searchValue === "") {
      // Se a string de pesquisa está vazia, basta configurar 'forms' de volta para 'cleanForms'
      setForms(cleanForms);
    } else {
      // Caso contrário, faça a busca e atualize o estado de 'forms' com os resultados
      searchAllByObj({
          q: searchValue,
          companyId: company.companyId,
          unitId: company.unitId
      })
        .then((response) => {
          setForms(
            response.message.map((values) => ({
              _id: values._id,
              name: values.name,
              shortName: values.name.replace("obj_", ""),
              categories: values.categories.join(", "),
            }))
          );
        })
        .catch((err) =>
          notification["error"]({
            message: "Erro",
            description: err.message
              ? err.message
              : "Algo está errado, tente novamente!",
          })
        );
    }
  };

  const pushHit = ({ id, index, name, route }) => {
    addHitByObj({
      id: id,
      index: index,
    })
      .then(async (response) => {
        const value = encodeURIComponent(name);
        history.push(`/formularios/${route}/${value}`);
      })
      .catch((err) =>
        notification["error"]({
          message: "Erro",
          description: err.message
            ? err.message
            : "Algo está errado, tente novamente!",
        })
      );
  };

  const columns = [
    {
      // title: FilterByNameInput,
      title: "Formulário",
      dataIndex: "shortName",
      key: "shortName",
      render: (text, record) => (
        <>
          <Avatar
            size="small"
            icon={<FileSyncOutlined />}
            style={{
              backgroundColor: "#12b398",
            }}
          />
          <span
            style={{
              marginLeft: 15,
            }}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: "Categorias",
      dataIndex: "categories",
      key: "categories",
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => {
          const permissions = permissionEntity.filter(item =>  item.context &&  item.context.entity_id === record._id);
          return (
              <Space size="middle">
                  {
                      checkPermissionsFromUser(permissions, [
                          'DELETE_FORM',
                          'CREATE_FIELD_IN_FORM',
                          'EDIT_FIELD_IN_FORM',
                          'DELETE_FIELD_IN_FORM',
                          'ORDER_FIELDS_IN_FORM',
                          'INSERT_DATA_IN_FORM',
                          'EDIT_DATA_IN_FORM',
                          'IMPORT_DATA_IN_FORM',
                          'VIEW_DATA_ARCHIVED_FROM_FORM'
                      ], company.unitId,'form',record._id) && (
                          <a href
                             onClick={() =>
                                 pushHit({
                                     id: record._id,
                                     index: "forms",
                                     name: record.name,
                                     route: "detalhes",
                                 })
                             }
                          >
                              Configurações
                          </a>)
                  }
                  {
                      checkPermissionsFromUser(permissions, [
                          'INSERT_DATA_IN_FORM',
                          'EDIT_DATA_IN_FORM',
                          'VIEW_DATA_FROM_FORM',
                          'VIEW_LOG_FROM_FORM'
                      ], company.unitId,'form',record._id) && (<a
                          href
                          onClick={() =>
                              pushHit({
                                  id: record._id,
                                  index: "forms",
                                  name: record.name,
                                  route: "registros",
                              })
                          }
                      >
                          Registros
                      </a>)
                  }

              </Space>
          );
      },
    },
  ];

    return (
        <Layout
            style={{
                height: "110vh",
            }}
        >
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout style={{padding: "0 24px 24px", minHeight: "100vh"}}>
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Lista de formulários"
              subTitle="Utilize a biblioteca de formulários para administrar novos campos e funções."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                background: "white",
              }}
            >
              <Row gutter={16} justify="end">
                <Col span={10}>
                  <Input
                    placeholder="Pesquisar por nome"
                    value={searchText}
                    onChange={handleSearch}
                  />
                </Col>
                <Col md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 4 }}>
                    <PermissionWrapperComponent permissions={['CREATE_FORM']}>
                        <div
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={newForm}
                                style={{width: "100%"}}
                            >
                                Adicionar Formulário
                                <PlusCircleOutlined/>
                            </Button>
                        </div>
                    </PermissionWrapperComponent>
                </Col>
              </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            dataSource={forms}
                            locale={{
                                emptyText: (
                                    <Empty description="Nenhum formulário adicionado."/>
                                ),
                            }}
                        />
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(AllForms);
